import React, { useEffect } from "react"
import { v4 as uuid } from "uuid"
import Input from "./Input"
import Select from "./Select"
import "./Form.css"
import validators from "../helpers/validators"
import randomiseArr from "../helpers/randomiseArr"
import { cats } from "../data/scenarios"

const catArr = randomiseArr(cats)

interface FormProps {
  className: string
  scenarioType: ScenarioType
  title: string
  handleTitleChange: HandleEvent
  details: string
  handleDetailsChange: HandleEvent
  development: string
  handleDevelopmentChange: HandleEvent
  participants: string
  setParticipantVal: (value: string, id: string) => void
  handleParticipantChange: HandleEvent
  participantsArr: Participant[]
  setParticipantsArrVal: (value: Participant[]) => void
  randomScenario: () => void
  incidentManager: string
  handleRoleChange: (e: E) => void
  updateRole: (name: string, value: any) => void
  facilitator: string
  operations: string[]
  handleStageChange: HandleEvent
}

const Form = (props: FormProps): JSX.Element => {
  const {
    scenarioType,
    title,
    handleTitleChange,
    details,
    handleDetailsChange,
    development,
    handleDevelopmentChange,
    participants,
    setParticipantVal,
    handleParticipantChange,
    participantsArr,
    setParticipantsArrVal,
    randomScenario,
    incidentManager,
    handleRoleChange,
    updateRole,
    facilitator,
    operations,
    handleStageChange,
  } = props
  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
  }
  function addParticipants(participant: string) {
    let newParticipants
    if (scenarioType === "live") {
      newParticipants = participant
        .replace(" ", "")
        .split(/[,;" "]/)
        .filter((participant) => validators.email(participant))
    } else {
      newParticipants = participant
        .split(/[,;]/)
        .map((p) => p.trim())
        .filter((p) => p !== "")
    }
    // const uniqueEmails = [...new Set(newEmails)] // Set may not be supported by some browsers...?
    const uniqueParticipants = newParticipants.reduce(function (a: string[], b: string) {
      if (a.indexOf(b) < 0) {
        a.push(b)
      }
      return a
    }, [])
    const participantObjects = uniqueParticipants.map((p) => {
      return { name: p, id: uuid(), img: catArr.shift().url }
    })
    if (participantsArr) {
      setParticipantsArrVal([...participantsArr, ...participantObjects])
      sessionStorage.setItem("RedAlertDrill_participantsArr", JSON.stringify(participantsArr))
    } else {
      setParticipantsArrVal(participantObjects)
    }
    const currentFacilitator = (document.getElementById("Facilitator") as HTMLInputElement).value
    if (!currentFacilitator) {
      updateRole("Facilitator", participantObjects[0].id)
    }
    const currentIncidentManager = (document.getElementById("Incident-manager") as HTMLInputElement).value
    if (!currentIncidentManager) {
      updateRole("Incident manager", participantObjects[0].id)
    }
    // updateRole("Facilitator", (document.getElementById("Facilitator") as HTMLInputElement).value)
    // updateRole("Incident manager", (document.getElementById("Incident-manager") as HTMLInputElement).value)
    const form = document.getElementById("drillForm") as HTMLFormElement
    const participantsInput = form.querySelector('[name="Participants"]') as HTMLInputElement
    participantsInput.required = false
    participantsInput.setCustomValidity("")
    // updateRole("Operations", (document.getElementById("Operations") as HTMLInputElement).selec)
  }
  function removeParticipant(e: E) {
    setParticipantsArrVal(participantsArr!.filter((p) => p.id !== e.currentTarget.id))
    sessionStorage.setItem("RedAlertDrill_participantsArr", JSON.stringify(participantsArr))
    if (participantsArr.length < 2) {
      // less than 2 because the participantsArr hasn't had a chance to update yet
      const form = document.getElementById("drillForm") as HTMLFormElement
      const participantsInput = form.querySelector('[name="Participants"]') as HTMLInputElement
      participantsInput.required = true
      updateRole("Facilitator", "")
      updateRole("Incident manager", "")
    }
  }
  useEffect(() => {
    console.log("useEffect")
    sessionStorage.setItem("RedAlertDrill_participantsArr", JSON.stringify(participantsArr))
  }, [participantsArr])
  function handleBlur(e: E) {
    addParticipants(e.currentTarget.value)
    setParticipantVal("", "Participants")
  }
  function handleEnter(e: E) {
    if (e.key === "Enter") {
      e.preventDefault()
      addParticipants(e.currentTarget.value)
      setParticipantVal("", "Participants")
    }
  }
  return (
    <div className='Form shadow p-3 mb-5 rounded-bottom'>
      <p className='lead'>Fill out the form below or generate a random scenario.</p>
      <button className='btn btn-sm' onClick={randomScenario}>
        Generate random!
      </button>
      <hr />
      <form onSubmit={handleSubmit} noValidate id='drillForm'>
        <h4>Scenario:</h4>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Input
              name='Title'
              placeholder='Enter a scenario title'
              handleChange={handleTitleChange}
              value={title}
              req={true}
              error='Please enter a title for your scenario'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Input
              type='textarea'
              name='Details'
              placeholder='Enter details for the scenario'
              handleChange={handleDetailsChange}
              value={details}
              req={true}
              error='Please enter a description for your scenario'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Input
              type='textarea'
              name='Development'
              placeholder='Optionally include a development that complicates to situation to really test your incident response.'
              // rows={4}
              handleChange={handleDevelopmentChange}
              value={development}
            />
          </div>
        </div>
        <hr />
        <div className='row mb-3'>
          <div className='col form-group'>
            <Input
              type=''
              name='Participants'
              placeholder={
                scenarioType === "live"
                  ? "participant1@example.com, participant2@example.com, ..."
                  : "Amy, Bob, Jamie, ..."
              }
              handleChange={handleParticipantChange}
              handleBlur={handleBlur}
              handleEnter={handleEnter}
              value={participants}
              req={true}
              error='Please add at least one participant'
            />
            {participantsArr &&
              participantsArr
                .filter((p) => p.role !== "Incident manager" && p.role !== "Facilitator")
                .map((p) => (
                  <span className='badge mx-1 mt-2' key={p.id}>
                    {p.name}
                    <button
                      type='button'
                      className='btn-close btn-close-white mx-1'
                      aria-label='Remove'
                      key={p.id}
                      id={p.id}
                      onClick={removeParticipant}
                    />
                  </span>
                ))}
          </div>
        </div>
        <h4>Roles:</h4>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Select
              name='Facilitator'
              handleChange={handleRoleChange}
              value={facilitator}
              options={participantsArr} //.filter((p) => p.id !== incidentManager && !operations.includes(p.id))}
              req={true}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Select
              name='Incident manager'
              handleChange={handleRoleChange}
              value={incidentManager}
              options={participantsArr} //.filter((p) => p.id !== facilitator && !operations.includes(p.id))}
              req={true}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col form-group'>
            <Select
              name='Operations'
              type='multi'
              handleChange={handleRoleChange}
              value={operations}
              options={participantsArr} //.filter((p) => p.id !== facilitator && p.id !== incidentManager)}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col form-group'>
            <button className='btn' data-stage='exercise' type='button' onClick={handleStageChange}>
              Begin exercise
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default Form
