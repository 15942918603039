import React, { useState } from "react"
import "./App.css"
import NavBar from "./components/Navbar"
import Heading from "./components/Heading"
import Form from "./components/Form"
import FormHeading from "./components/FormHeading"
import { scenarios, steps } from "./data/scenarios"
import randomiseArr from "./helpers/randomiseArr"
import useFormState from "./hooks/useFormState"
import Exercise from "./components/Exercise"
import End from "./components/End"

function App() {
  const [scenarioType] = useState<ScenarioType>("tableTop")
  const [timer, setTimer] = useState(0)
  const [step, setStep] = useState<Step>(steps[0])
  const [stage, setStage] = useState<Stage>("form")
  const [title, setTitleVal, handleTitleChange] = useFormState(sessionStorage.RedAlertDrill_Title || "")
  const [details, setDetailsVal, handleDetailsChange] = useFormState(sessionStorage.RedAlertDrill_Details || "")
  const [development, setDevelopmentVal, handleDevelopmentChange] = useFormState(
    sessionStorage.RedAlertDrill_Development || ""
  )
  const [participants, setParticipantVal, handleParticipantChange] = useFormState("")
  const [participantsArr, setParticipantsArrVal] = useState<Participant[]>(
    sessionStorage.RedAlertDrill_participantsArr ? JSON.parse(sessionStorage.RedAlertDrill_participantsArr) : []
  )
  const [incidentManager, setIncidentManagerChange] = useState(sessionStorage.RedAlertDrill_incidentManager || "")
  const [facilitator, setFacilitatorChange] = useState(sessionStorage.RedAlertDrill_facilitator || "")
  const [operations, setOperationsChange] = useState(
    sessionStorage.RedAlertDrill_operations ? JSON.parse(sessionStorage.RedAlertDrill_operations) : []
  )
  const [randomScenarios] = useState(
    sessionStorage.RedAlertDrill_scenarios
      ? JSON.parse(sessionStorage.RedAlertDrill_scenarios)
      : randomiseArr(scenarios)
  )
  const [currentScenario, setCurrentScenario] = useState(0)

  // let currentScenario = 0

  function randomScenario(): void {
    !sessionStorage.RedAlertDrill_scenarios &&
      sessionStorage.setItem("RedAlertDrill_scenarios", JSON.stringify(randomScenarios))
    const s = randomScenarios[currentScenario]
    setTitleVal(s.title, "Title")
    setDetailsVal(s.details, "Details")
    setCurrentScenario(currentScenario >= randomScenarios.length - 1 ? 0 : currentScenario + 1)
  }
  function handleStageChange(e: E): void {
    // if (e.currentTarget.attributes["data-stage"].value === "splash") {
    //   setStage("splash")
    // }
    if (e.currentTarget.attributes["data-stage"].value === "form") {
      setStage("form")
    }
    if (e.currentTarget.attributes["data-stage"].value === "exercise") {
      const form = document.getElementById("drillForm") as HTMLFormElement
      const participantsInput = form.querySelector('[name="Participants"]') as HTMLInputElement
      if (participantsArr.length === 0) {
        participantsInput.setCustomValidity("invalid")
      } else {
        participantsInput.required = false
        participantsInput.setCustomValidity("")
      }
      if (!form.checkValidity()) {
        return form.classList.add("was-validated")
      }
      setStage("exercise")
      timer === 0 && setTimer(Date.now())
    }
  }
  function handleRoleChange(e: E): void {
    const name = e.currentTarget.name
    const value = name === "Operations" ? Array.from(e.currentTarget.selectedOptions) : e.currentTarget.value
    updateRole(name, value)
  }
  function updateRole(name: string, value: any) {
    if (name === "Facilitator") {
      sessionStorage.setItem(`RedAlertDrill_facilitator`, value)
      return setFacilitatorChange(value)
    }
    if (name === "Incident manager") {
      sessionStorage.setItem(`RedAlertDrill_incidentManager`, value)
      return setIncidentManagerChange(value)
    }
    if (name === "Operations") {
      sessionStorage.setItem("RedAlertDrill_operations", JSON.stringify(value.map((option: any) => option.value)))
      return setOperationsChange(value.map((option: any) => option.value))
    }
  }

  if (stage === "form") {
    return (
      <div className='App'>
        <NavBar />
        <Heading handleStageChange={handleStageChange} />
        <div className='formContainer py-5 m-0'>
          <div className='container my-5 Scenario'>
            {/* <div className='FormContainer'> */}
            <FormHeading title='Complete this form to get started' />
            <Form
              className=''
              scenarioType={scenarioType}
              title={title}
              handleTitleChange={handleTitleChange}
              details={details}
              handleDetailsChange={handleDetailsChange}
              development={development}
              handleDevelopmentChange={handleDevelopmentChange}
              participants={participants}
              setParticipantVal={setParticipantVal}
              handleParticipantChange={handleParticipantChange}
              participantsArr={participantsArr}
              setParticipantsArrVal={setParticipantsArrVal}
              randomScenario={randomScenario}
              incidentManager={incidentManager}
              handleRoleChange={handleRoleChange}
              updateRole={updateRole}
              // handleIncidentManagerChange={handleIncidentManagerChange}
              facilitator={facilitator}
              // handleFacilitatorChange={handleFacilitatorChange}
              operations={operations}
              // handleOperationsChange={handleOperationsChange}
              handleStageChange={handleStageChange}
              // timer={timer}
              // setTimer={setTimer}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    )
  }
  if (stage === "exercise") {
    return (
      <div>
        {/* <NavBar /> */}
        <Exercise
          title={title}
          details={details}
          development={development}
          step={step}
          setStep={setStep}
          participantsArr={participantsArr}
          setStage={setStage}
          facilitator={facilitator}
          incidentManager={incidentManager}
          operations={operations}
          timer={timer}
        />
      </div>
    )
  }
  if (stage === "end") {
    return (
      <div>
        <NavBar />
        <End />
        {/* <End setStage={setStage} /> */}
      </div>
    )
  }
  return <h1>nothing rendered</h1>
}

export default App
