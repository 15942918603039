import "./End.css"

// interface EndProps {
//   setStage: React.Dispatch<React.SetStateAction<Stage>>
// }
const End = () => {
  // const End = (props: EndProps) => {
  // const { setStage } = props
  // function handleResetExercise() {
  //   setStage("form")
  // }
  return (
    <div className='Heading container mt-5'>
      <div className='row'>
        <div className='col text-center'>
          <h1 className='display-4'>Thanks for using Red Alert: Drill</h1>
          <div className='col-lg-6 mx-auto'>
            <p className='lead mb-5'>You have taken an important step to improving your incident response!</p>
            <p className=''>
              If you have any feedback or suggestions to improve this tool, we'd love to hear from you!
            </p>
            <button
              className='btn RA-btn px-4 me-sm-3 mb-4'
              data-bs-toggle='modal'
              data-bs-target='#drillFeedbackModal'
            >
              Give feedback
            </button>
            <p className=''>Start over with a new exercise</p>
            <div className='d-grid gap-2 d-sm-flex justify-content-sm-center mb-5'>
              <a className='btn RA-btn px-4 me-sm-3' href='https://drill.redalert.cloud'>
                Start over
              </a>
              {/* <button className='btn RA-btn px-4 me-sm-3' onClick={handleResetExercise}>
                Start over
              </button> */}
            </div>
            <hr />
            <p className='lead'>Want to take your incident response to the next level?</p>
            <p className='lead mb-4'>
              Red Alert is a dedicated major incident management platform that takes the stress out of managing major
              incidents.
            </p>
            <div className='d-grid gap-2 d-sm-flex justify-content-sm-center mb-5'>
              <a className='btn RA-btn btn-lg px-4 me-sm-3' href='https://redalert.cloud?src=drill'>
                Check it out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default End
