// import React, {useState} from "react";
// import useFormState from "../hooks/useFormState"

import { SetStateAction } from "react"

interface InputProps {
  type?: string
  name: string
  placeholder: string
  rows?: number
  value: string
  noteId?: number
  req?: boolean
  error?: string
  //   handleChange: (arg:string) =>{}
  // handleChange: React.Dispatch<React.SetStateAction<string>>
  handleChange: (e: E) => void
  handleBlur?: (e: E) => void
  handleEnter?: (e: E) => void
}
const Input = (props: InputProps) => {
  const { type, name, placeholder, rows, value, handleChange, handleBlur, handleEnter, noteId, req, error } = props
  const input = (type: string) => {
    if (type === "textarea") {
      return (
        <textarea
          className='form-control'
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          rows={rows}
          data-noteid={noteId}
          required={req}
        />
      )
    }
    if (type === "select") {
      return <select className='form-control' name={name} id={name} onChange={handleChange} value={value} />
    }
    return (
      <input
        className='form-control'
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur && handleBlur}
        onKeyDown={handleEnter && handleEnter}
        value={value}
        required={req}
      />
    )
  }
  return (
    <div>
      <label className='form-label' htmlFor={name}>
        {name === "Development" ? `${name} (optional)` : name}
      </label>
      {input(type ? type : "")}
      {req && <div className='invalid-feedback'>{error}</div>}
    </div>
  )
}
export default Input
