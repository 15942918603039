import "./Thumbnail.css"

interface ThumbnailProps {
  role: string
  id: string
  name: string
  img: string
}
const Thumbnail = (props: ThumbnailProps) => {
  const { role, id, name, img } = props
  return (
    <div className='col-6 col-md-4 col-lg-3 col-xl-2 border rounded p-0 m-3 shadow Thumbnail' key={id}>
      <div className='ThumbnailName rounded-top'>
        <p className='text-center fw-bold py-2'>{name}</p>
      </div>

      <img className='d-block my-3 mx-auto rounded smol' src={img} alt={name} />
      <p className='text-center fw-bold py-3 m-0'>{role}</p>
    </div>
  )
}
export default Thumbnail
