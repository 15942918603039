import "./ProgressBar.css"

interface ProgressBarProps {
  step: Step
  steps: Step[]
}

const ProgressBar = (props: ProgressBarProps) => {
  const { step, steps } = props
  return (
    <div className='ProgressBar'>
      <div className='progress'>
        <div
          className='progress-bar'
          role='progressbar'
          aria-valuenow={70}
          aria-valuemin={0}
          aria-valuemax={10}
          style={{ width: `${(step.id / steps.length) * 100}%` }}
        >
          <span className='sr-only'>{step.label}</span>
        </div>
      </div>
    </div>
  )
}
export default ProgressBar
