// type Scenario = {
//     title: string;
//     details?: string;
// }
// type Scenarios = Scenario[]

export const scenarios: Scenarios = [
  {
    title: "Power outage to office",
    details:
      "The main office is offline due to a power cut. Staff at the office are unable to work but they may have access to information on their phones.",
  },
  {
    title: "Email is down",
    details: "Staff are reporting that they can't receive new emails or send emails.",
  },
  {
    title: "Encrypted files on the network share",
    details:
      "A user has reported that they can't open any of the files they normally use on the network share. Some mention of encrypted files...",
  },
  {
    title: "80% of IT support crew are away sick",
    details:
      "After last weeks office party, 80% of the IT support crew are away sick, which is severely impacting business operations.",
    development:
      "The Incident manager is starting to feel unwell and will need to hand over the management of this incident to another person.",
  },
  {
    title: "A natural disaster has prevented access to the office",
    details:
      "A natural disaster has struck the local office, staff have been reported as safe but access to the office is off limits.",
  },
  {
    title: "A security breach has been detected on the network",
    details: "During a routine audit, evidence that the internal network has been breached has been found.",
  },
]

export const steps = [
  {
    id: 1,
    label: "Starring...",
    feature: { heading: "", blurb: "" },
  },
  {
    id: 2,
    label: "Set the scene",
    // feature: { heading: "Scene heading", blurb: "Scene blurb"},
    discussion: [
      "Does the organisation have a plan in place to deal with this kind of incident?",
      "Are the roles clear and able to cover all aspects of this kind of incident?",
    ],
  },
  {
    id: 3,
    label: "Initial assessment",
    feature: { heading: "Discussion", blurb: "Assess the impact of this incident" },
    discussion: [
      "What does this incident mean for the organisation?",
      "How would this incident be classified in terms of priority? E.g. P1 / P2",
    ],
  },
  {
    id: 4,
    label: "Initial actions",
    feature: { heading: "Get to work", blurb: "It's time to discuss how the team will tackle this incident." },
    discussion: [
      "What is the first action that the team will take? Let the scenario play out from there.",
      "Do you have enough information about this incident? The facilitator can fill out any hypothetical questions you may have.",
    ],
  },
  {
    id: 5,
    label: "New development",
    feature: { heading: "Wait... there's been a development", blurb: "" },
    discussion: [
      "How does this new development change the approach to managing this incident?",
      "Does the plan account for this kind of situation?",
    ],
  },
  {
    id: 6,
    label: "Incident resolved",
    feature: {
      heading: "Congratulations, you have resolved the incident!",
      blurb:
        "Great effort and solid teamwork has paid off. You can breathe a sigh of relief, the incident has been resolved.",
    },
    discussion: ["In the next section we will review what went well and what didn't go well."],
  },
  {
    id: 7,
    label: "Review",
    feature: {
      heading: "Let's review how this incident went.",
      blurb: "This is perhaps the most important step in this exercise.",
    },
    discussion: [
      "1. Did the team adhere to the plan?",
      "2. What went well during the incident? E.g. Were you able to keep everyone informed throughout the incident?",
      "3. What didn't go so well and will need to be improved? E.g. Were there gaps in your plan or did it not account for any unexpected issues?",
      "4. What changes will be made as a result of this exercise, by whom and by when?",
      "5. What is the date for the next exercise?",
    ],
  },
  {
    id: 8,
    label: "Results",
    feature: {
      heading: "Results",
      blurb:
        "Well done for concluding this exercise. Below is a summary of what was covered during this exercise as well as any notes you have recorded along the way.",
    },
  },
]

export const wildcards = [
  " has just slipped on a banana peel! They will need a lie down in a quiet room and will not be able to participate any further in the scenario.",
  " just received an extraordinary job offer from a rival organisation and has simply walked out with a smug look on their face. They will not be able to participate any further in the scenario.",
  " has been spotted whispering into a secret shoe phone. They are suspected of being a spy and potentially causing this whole mess in the first place. Best that they don't participate in any further in this scenario.",
]

// - Power outage to HQ
// - Email offline
// - User reports encrypted files
// - 80% of support team unwell
// - Natural disaster prevents anyone from accessing the office
// - Storage problem prevents access to data
// - Strange network issue causing slow network access intermittently
// - Internet down at office
// - Security breach detected

export const cats = [
  {
    id: "1tm",
    url: "https://cdn2.thecatapi.com/images/1tm.jpg",
    width: 440,
    height: 447,
  },
  {
    id: "2hk",
    url: "https://cdn2.thecatapi.com/images/2hk.jpg",
    width: 500,
    height: 375,
  },
  {
    id: "3ec",
    url: "https://cdn2.thecatapi.com/images/3ec.jpg",
    width: 500,
    height: 341,
  },
  {
    id: "46c",
    url: "https://cdn2.thecatapi.com/images/46c.jpg",
    width: 3264,
    height: 2448,
  },
  {
    id: "cb7",
    url: "https://cdn2.thecatapi.com/images/cb7.jpg",
    width: 800,
    height: 808,
  },
  {
    id: "MTc3MTg4Mg",
    url: "https://cdn2.thecatapi.com/images/MTc3MTg4Mg.jpg",
    width: 456,
    height: 652,
  },
  {
    id: "MTc3NDU2MQ",
    url: "https://cdn2.thecatapi.com/images/MTc3NDU2MQ.jpg",
    width: 1024,
    height: 680,
  },
  {
    id: "MjAzOTQ2MA",
    url: "https://cdn2.thecatapi.com/images/MjAzOTQ2MA.jpg",
    width: 375,
    height: 500,
  },
  {
    id: "br3TZZZlR",
    url: "https://cdn2.thecatapi.com/images/br3TZZZlR.jpg",
    width: 768,
    height: 612,
  },
  {
    id: "34qVmAAqH",
    url: "https://cdn2.thecatapi.com/images/34qVmAAqH.jpg",
    width: 1240,
    height: 698,
  },
  {
    id: "ts",
    url: "https://cdn2.thecatapi.com/images/ts.jpg",
    width: 1125,
    height: 1500,
  },
  {
    id: "3t4",
    url: "https://cdn2.thecatapi.com/images/3t4.jpg",
    width: 500,
    height: 375,
  },
  {
    id: "a00",
    url: "https://cdn2.thecatapi.com/images/a00.jpg",
    width: 640,
    height: 480,
  },
  {
    id: "a2q",
    url: "https://cdn2.thecatapi.com/images/a2q.jpg",
    width: 1356,
    height: 2048,
  },
  {
    id: "a7h",
    url: "https://cdn2.thecatapi.com/images/a7h.jpg",
    width: 448,
    height: 336,
  },
  {
    id: "b0h",
    url: "https://cdn2.thecatapi.com/images/b0h.jpg",
    width: 500,
    height: 333,
  },
  {
    id: "bdb",
    url: "https://cdn2.thecatapi.com/images/bdb.jpg",
    width: 500,
    height: 667,
  },
  {
    id: "MTYyNDMwMg",
    url: "https://cdn2.thecatapi.com/images/MTYyNDMwMg.jpg",
    width: 3264,
    height: 2448,
  },
  {
    id: "MjAwMjk5MQ",
    url: "https://cdn2.thecatapi.com/images/MjAwMjk5MQ.jpg",
    width: 900,
    height: 600,
  },
  {
    id: "gLfmOKHCA",
    url: "https://cdn2.thecatapi.com/images/gLfmOKHCA.jpg",
    width: 1244,
    height: 1600,
  },
  {
    id: "3j4",
    url: "https://cdn2.thecatapi.com/images/3j4.jpg",
    width: 1200,
    height: 1200,
  },
  {
    id: "7g4",
    url: "https://cdn2.thecatapi.com/images/7g4.jpg",
    width: 400,
    height: 521,
  },
  {
    id: "8vl",
    url: "https://cdn2.thecatapi.com/images/8vl.jpg",
    width: 1024,
    height: 685,
  },
  {
    id: "9d6",
    url: "https://cdn2.thecatapi.com/images/9d6.jpg",
    width: 500,
    height: 313,
  },
  {
    id: "ab3",
    url: "https://cdn2.thecatapi.com/images/ab3.jpg",
    width: 500,
    height: 375,
  },
  {
    id: "b9c",
    url: "https://cdn2.thecatapi.com/images/b9c.jpg",
    width: 500,
    height: 334,
  },
  {
    id: "blc",
    url: "https://cdn2.thecatapi.com/images/blc.jpg",
    width: 689,
    height: 1024,
  },
  {
    id: "MTk4OTg5Mw",
    url: "https://cdn2.thecatapi.com/images/MTk4OTg5Mw.jpg",
    width: 900,
    height: 563,
  },
  {
    id: "mK0P2HlfO",
    url: "https://cdn2.thecatapi.com/images/mK0P2HlfO.jpg",
    width: 1416,
    height: 873,
  },
  {
    id: "EacqcTsmf",
    url: "https://cdn2.thecatapi.com/images/EacqcTsmf.jpg",
    width: 600,
    height: 337,
  },
  {
    id: "lt",
    url: "https://cdn2.thecatapi.com/images/lt.jpg",
    width: 600,
    height: 542,
  },
  {
    id: "avu",
    url: "https://cdn2.thecatapi.com/images/avu.jpg",
    width: 3000,
    height: 3000,
  },
  {
    id: "bci",
    url: "https://cdn2.thecatapi.com/images/bci.jpg",
    width: 500,
    height: 312,
  },
  {
    id: "cfn",
    url: "https://cdn2.thecatapi.com/images/cfn.jpg",
    width: 1920,
    height: 1080,
  },
  {
    id: "d5h",
    url: "https://cdn2.thecatapi.com/images/d5h.jpg",
    width: 1545,
    height: 1024,
  },
  {
    id: "MTUzOTI1MA",
    url: "https://cdn2.thecatapi.com/images/MTUzOTI1MA.jpg",
    width: 600,
    height: 600,
  },
  {
    id: "MTcwODY3OA",
    url: "https://cdn2.thecatapi.com/images/MTcwODY3OA.gif",
    width: 500,
    height: 533,
  },
  {
    id: "MTg1NjQwNw",
    url: "https://cdn2.thecatapi.com/images/MTg1NjQwNw.jpg",
    width: 700,
    height: 452,
  },
  {
    id: "Y2MO5GWHO",
    url: "https://cdn2.thecatapi.com/images/Y2MO5GWHO.jpg",
    width: 700,
    height: 443,
  },
  {
    id: "AJxcCRZeO",
    url: "https://cdn2.thecatapi.com/images/AJxcCRZeO.jpg",
    width: 680,
    height: 453,
  },
]
