import { steps } from "../data/scenarios"
import "./Results.css"

interface ResultsProps {
  time: number
  participantsArr: Participant[]
  facilitator: string
  incidentManager: string
  operations: string[]
  title: string
  details: string
  development: string
  stepNotes: StepNote[]
}
const Results = (props: ResultsProps) => {
  const { time, participantsArr, facilitator, incidentManager, operations, title, details, development, stepNotes } =
    props
  const ms = Date.now() - time
  const mins = Math.round((ms / 1000 / 60) % 60)
  // const hrs = ms / 1000 / 60 / 60
  const hrs = Math.floor(ms / 1000 / 60 / 60)
  return (
    <div className='Results'>
      <h1 className='display-4 text-center mt-5'>Exercise results</h1>
      <hr />
      <p className='mb-1'>
        <span className='fw-bold'>Scenario: </span>
        {title}
      </p>
      <p className='mb-1'>{details}</p>
      <p className='mb-1'>
        <span className='fw-bold'>Date conducted: </span>
        {new Date().toDateString()}
      </p>
      <p className='mb-1'>
        <span className='fw-bold'>Time taken: </span>
        {`${hrs} hrs, ${mins}, mins`}
      </p>
      <h3 className='mt-4'>Participants:</h3>
      <p className='mb-1'>
        <span className='fw-bold'>Facilitator:</span> {participantsArr.filter((p) => p.id === facilitator)[0]?.name}
      </p>
      <p className='mb-1'>
        <span className='fw-bold'>Incident manager:</span>{" "}
        {participantsArr.filter((p) => p.id === incidentManager)[0]?.name}
      </p>
      <p className='mb-1'>
        <span className='fw-bold'>Operations: </span>
        {participantsArr.map((p, i) => {
          if (operations.includes(p.id)) {
            return i === operations.length + 1 ? p.name : p.name + ", "
          }
          return null
        })}
      </p>
      <h3 className='mt-4'>Steps:</h3>
      {steps.map((step) => {
        if (step.label !== "Results" && step.label !== "Starring...") {
          return (
            <div key={step.id}>
              <h5 className='mb-1'>{`${step.id - 1}. ${step.label}`}</h5>
              <h6>Notes: </h6>
              {/* <span className='fw-bold'>Notes: </span> */}
              <p>{stepNotes.filter((n) => Number(n.id) === step.id)[0]?.note}</p>
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default Results
