import Thumbnail from "./Thumbnail"

interface RoleIntroProps {
  participantsArr: Participant[]
  facilitator: string
  incidentManager: string
  operations: string[]
  // roles: Role[]
}

const RoleIntro = (props: RoleIntroProps): JSX.Element => {
  const { participantsArr, facilitator, incidentManager, operations } = props
  return (
    <div>
      <div className='row mb-5'>
        {participantsArr.map((p) => {
          let role = ""
          if (facilitator === p.id) {
            role += role.length === 0 ? "Facilitator" : " / Facilitator"
          }
          if (incidentManager === p.id) {
            role += role.length === 0 ? "Incident manager" : " / Incident manager"
          }
          if (operations.includes(p.id)) {
            role += role.length === 0 ? "Operations" : " / Operations"
          }
          return <Thumbnail role={role} id={p.id} name={p.name} img={p.img} key={p.id} />
        })}
      </div>
    </div>
  )
}
export default RoleIntro
