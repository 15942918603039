import { useState } from "react"

type UseFormState = [value: string, setVal: (value: string, id: string) => void, handleChange: HandleEvent]

const useFormState = (initialVal: string): UseFormState => {
  const [value, setValue] = useState(initialVal)

  const setVal = (value: string, id: string): void => {
    setValue(value)
    sessionStorage.setItem(`RedAlertDrill_${id.replace(" ", "_")}`, value)
  }
  const handleChange: HandleEvent = (e) => {
    setValue(e.currentTarget.value)
    sessionStorage.setItem(`RedAlertDrill_${e.currentTarget.id.replace(" ", "_")}`, e.currentTarget.value)
  }
  // const handleBlur: HandleEvent = (e) => {
  //   console.log("blur")
  // }
  return [value, setVal, handleChange]
}
export default useFormState
