import React from "react"
import "./FormHeading.css"
interface FormHeadingProps {
  title: string
}

const FormHeading = (props: FormHeadingProps) => {
  return (
    <div className='FormHeading d-flex flex-row shadow py-2 px-3 rounded-top' id='FormHeading'>
      <div className='flex-fill align-self-center'>
        <h5>{props.title}</h5>
      </div>
    </div>
  )
}
export default FormHeading
