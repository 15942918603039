import React, { useState } from "react"
// import useFormState from "../hooks/useFormState"
import "./Exercise.css"
import Input from "./Input"
import RoleIntro from "./RoleIntro"
import Feature from "./Feature"
import { steps } from "../data/scenarios"
import Results from "./Results"
import ProgressBar from "./ProgressBar"

interface ExerciseProps {
  title: string
  details: string
  development: string
  step: Step
  setStep: React.Dispatch<React.SetStateAction<Step>>
  participantsArr: Participant[]
  setStage: React.Dispatch<React.SetStateAction<Stage>>
  facilitator: string
  incidentManager: string
  operations: string[]
  timer: number
}

const Exercise = (props: ExerciseProps) => {
  const {
    title,
    details,
    development,
    step,
    setStep,
    participantsArr,
    setStage,
    facilitator,
    incidentManager,
    operations,
    timer,
  } = props
  // const [initialAssessmentVal, setInitialAssessmentVal, handleInitialAssessmentChange] = useFormState(
  //   sessionStorage.RedAlertDrill_Initial_assessment || ""
  // )
  const [stepNotes, setStepNotes] = useState<Array<StepNote>>(
    sessionStorage.RedAlertDrill_stepNotes ? JSON.parse(sessionStorage.RedAlertDrill_stepNotes) : []
  )
  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
  }
  function handleNextStep(e: E): void {
    if (step.id < 8) {
      let nextStep = steps.filter((s) => s.id === step.id + 1)
      if (nextStep[0].id === 5 && development.length < 1) {
        nextStep = steps.filter((s) => s.id === 6)
      }
      setStep(nextStep[0])
    }
  }
  function handlePreviousStep(e: E): void {
    // setStep(steps[0])
    if (step.id > 1) {
      // setStep(steps[steps.indexOf(step) - 1])
      let prevStep = steps.filter((s) => s.id === step.id - 1)
      if (prevStep[0].id === 5 && development.length < 1) {
        prevStep = steps.filter((s) => s.id === 4)
      }
      setStep(prevStep[0])
    } else {
      setStage("form")
    }
  }
  function handleStepNotesChange(e: E): void {
    const noteId: string = e.currentTarget.attributes["data-noteid"].value
    const note: string = e.currentTarget.value
    const exists = stepNotes.filter((n) => n.id === noteId)
    if (exists.length > 0) {
      const updatedNotes = stepNotes.map((n) => {
        if (n.id === noteId) {
          return { id: noteId, note: note }
        } else {
          return n
        }
      })
      setStepNotes(updatedNotes)
    } else {
      setStepNotes([...stepNotes, { id: noteId, note: note }])
    }
    sessionStorage.setItem("RedAlertDrill_stepNotes", JSON.stringify(stepNotes))
  }
  function handleEndExercise() {
    if (
      window.confirm(
        "Do you want to end the exercise? This will clear your session, which will clear the scenario and any notes you have made."
      )
    ) {
      setStage("end")
      sessionStorage.clear()
    }
  }
  const heading = () => {
    if (step.id === 2) {
      return title || ""
    }
    if (step.id === 5) {
      return "There's been a development..."
    }
    return step.feature?.heading || ""
  }
  const blurb = () => {
    if (step.id === 2) {
      return details || ""
    }
    if (step.id === 5) {
      return development || ""
    }
    return step.feature?.blurb || ""
  }

  const prevStep = steps.filter((s) => s.id === step.id - 1)[0]
  const nextStep = steps.filter((s) => s.id === step.id + 1)[0]

  return (
    <div className='Exercise py-5'>
      <div className='exerciseBox container rounded shadow py-3'>
        <div className='mt-1 mb-3'>
          <ProgressBar step={step} steps={steps} />
        </div>
        <div className='exerciseBox '>
          <div className='row'>
            <div className='col'>
              <h3 className='mb-3 '>{step.label}</h3>
              <hr />
            </div>
          </div>
          {step.id === 1 ? (
            <div className='row'>
              <div className='col'>
                <p className='lead'>An elite group of highly trained experts!</p>
                <RoleIntro
                  participantsArr={participantsArr}
                  facilitator={facilitator}
                  incidentManager={incidentManager}
                  operations={operations}
                />
              </div>
            </div>
          ) : (
            <>
              <div className='row justify-content-center'>
                <div className='col col-md-10 col-lg-8'>
                  <Feature
                    heading={heading()}
                    blurb={blurb()}
                    // heading={step.id === 2 ? title : step.feature?.heading || ""}
                    // blurb={step.id === 2 ? details : step.feature?.blurb || ""}
                    // development={step.id === 5 ? development : development || ""}
                  />
                </div>
              </div>
              <div className='row'>
                {step.id === 8 ? (
                  <Results
                    time={timer}
                    participantsArr={participantsArr}
                    facilitator={facilitator}
                    incidentManager={incidentManager}
                    operations={operations}
                    title={title}
                    details={details}
                    development={development}
                    stepNotes={stepNotes}
                  />
                ) : (
                  <div className='col px-4'>
                    <p className='fw-bold mb-1 mt-5'>Discussion points:</p>
                    {step.discussion?.map((p) => {
                      return (
                        <div className='form-check' key={p}>
                          <input className='form-check-input' type='checkbox' value='' id={p.replace(" ", "-")} />
                          <label className='form-check-label' htmlFor={p.replace(" ", "-")}>
                            {p}
                          </label>
                        </div>
                      )
                    })}
                    <form onSubmit={handleSubmit}>
                      <div className='row my-3'>
                        <div className='col form-group'>
                          <Input
                            type='textarea'
                            name={step.label + " notes"}
                            placeholder='Record any notes here...'
                            rows={4}
                            handleChange={handleStepNotesChange}
                            value={stepNotes.filter((n) => Number(n.id) === step.id)[0]?.note || ""}
                            noteId={step.id}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className='d-flex '>
          {step.id > 1 ? (
            <span className='mx-2 align-items-center' onClick={handlePreviousStep} style={{ cursor: "pointer" }}>
              <span className='material-symbols-outlined'>navigate_before</span>
              <p className='d-inline'>{prevStep.label}</p>
            </span>
          ) : (
            <span className='mx-2 align-items-center' onClick={() => setStage("form")} style={{ cursor: "pointer" }}>
              <span className='material-symbols-outlined'>navigate_before</span>
              <p className='d-inline'>Back to form</p>
            </span>
          )}
          {step.id < 8 ? (
            <span className='me-2 ms-auto' onClick={handleNextStep} style={{ cursor: "pointer" }}>
              <p className='d-inline'>{nextStep.label}</p>
              <span className='material-symbols-outlined'>navigate_next</span>
            </span>
          ) : (
            <span className='me-2 ms-auto' onClick={handleEndExercise} style={{ cursor: "pointer" }}>
              <p className='d-inline'>End the exercise</p>
              <span className='material-symbols-outlined'>done</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Exercise
