import "./Feature.css"

interface FeatureProps {
  heading: string
  blurb: string
  // development: string
}
const Feature = (props: FeatureProps) => {
  const { heading, blurb } = props
  return (
    <div className='container-fluid p-0 Feature rounded'>
      <div className='row g-0'>
        <div className='col FeatureHeading'>
          <h1 className='display-5 text-center'>{heading}</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='p-4  my-auto'>
            <p className='text-center'>{blurb}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Feature
