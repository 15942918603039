import React from "react"
import "./Navbar.css"
import logo from "../img/RadopsLogo-sm.png"
import fbLogo from "../img/fb-white.png"
import twitterLogo from "../img/twitter-white.png"
import linkedinLogo from "../img/linkedin-white.png"

const NavBar = () => {
  return (
    <div className='Navbar '>
      <div className='container'>
        <header className='row' id='navbar-row'>
          <nav className='navbar navbar-expand-lg navbar-dark'>
            <div className='container-fluid'>
              <a className='navbar-brand' href='https://redalert.cloud'>
                <img src={logo} className='me-5 rounded d-none d-lg-inline' alt='RadOps Ltd' />
              </a>
              <a className='navbar-brand' href='https://redalert.cloud?src=drill'>
                | Red <span className='alert-red'>Alert</span> |
              </a>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                  <li className='nav-item'>
                    <a className='nav-link' href='https://redalert.cloud?src=drill#pricingDiv'>
                      Sign up
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      aria-current='page'
                      href='https://redalert.cloud?src=drill#aboutModal'
                      data-bs-toggle='modal'
                      data-bs-target='#aboutModal'
                    >
                      About
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='https://redalert.cloud?src=drill#pricingDiv'>
                      Pricing
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      aria-current='page'
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#formModal'
                    >
                      Contact
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      aria-current='page'
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#faqModal'
                    >
                      FAQ
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href='https://demo.redalert.cloud/new/demo'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Demo
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='https://drill.redalert.cloud/' target='_blank' rel='noreferrer'>
                      Drill
                    </a>
                  </li>
                  <li className='nav-item dropdown'>
                    <a
                      className='nav-link dropdown-toggle'
                      href='#'
                      id='navbarDropdown'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Info
                    </a>
                    <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <li>
                        <a
                          className='dropdown-item'
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://www.linkedin.com/company/radops'
                        >
                          RadOps (view on LinkedIn)
                        </a>
                      </li>
                      <li>
                        <a
                          className='dropdown-item'
                          href='https://redalert.cloud/privacy-policy.html'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Privacy
                        </a>
                      </li>
                      <li>
                        <a
                          className='dropdown-item'
                          href='https://redalert.cloud/terms-of-use.html'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Terms of use
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <li className='d-none d-lg-block'>
                <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/RadOpsLtd'>
                  <img src={fbLogo} alt='RadOps Facebook page' className='social-icon mx-2' />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/Rad_Ops'>
                  <img src={twitterLogo} alt='RadOps Twitter feed' className='social-icon mx-2' />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/company/radops'>
                  <img src={linkedinLogo} alt='RadOps LinkedIn page' className='social-icon mx-2' />
                </a>
              </li>
            </div>
          </nav>
        </header>
      </div>
    </div>
  )
}

export default NavBar
