import React from "react"

interface SelectProps {
  name: string
  type?: "multi" | "single"
  value: string | string[]
  options: Participant[]
  req?: boolean
  // options: [{participant: string, id: string}]
  handleChange: (e: E) => void
}

const Select = (props: SelectProps) => {
  const { name, type, value, options, req, handleChange } = props
  return (
    <div>
      <label className='form-label' htmlFor={name}>
        {name}
      </label>
      <select
        className='form-control'
        multiple={type === "multi" ? true : false}
        name={name}
        value={value}
        id={name.replace(" ", "-")}
        onChange={handleChange}
        required={req}
      >
        {options.map((o: Participant) => (
          <option value={o.id} key={o.id}>
            {o.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
